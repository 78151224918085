import $ from 'jquery';
/* global sprite_path */

$(document).ready(function($) {
  $('.slick-slider').slick({
    arrows: true,
        prevArrow: `<div class="arrow-slick arrow-slick-prev"><svg class="svg-left-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#left"></use></svg></div>`,
        nextArrow: `<div class="arrow-slick arrow-slick-next"><svg class="svg-right-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#right"></use></svg></div>`,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
