/* global wpcf7 */

import $ from 'jquery';

$(document).ready(function () {
  $('#recalledModal').on('show.bs.modal', function () {
    $.get('/wp-content/themes/isa/modal-recalled.php', function (data) {
      $('#recalledModal .modal-body').html(data);

      const form = $('#recalledModal .wpcf7-form').eq(0);
      wpcf7.init(form[0]);
    });
  });
});
