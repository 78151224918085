import $ from 'jquery';

const montant_projet = $('#montant_projet');
const montant_apport = $('#montant_apport');
const montant_emprunt = $('#montant_emprunt');

function computeBorrowAmount() {
  let borrow;
  borrow = Number.parseInt(montant_projet.val(), 10) - Number.parseInt(montant_apport.val(), 10);

  if (borrow < 0) {
    borrow = 0;
  }

  if (Number.isNaN(borrow)) {
    borrow = 0;
  }

  montant_emprunt.val(borrow);
}

$(document).on('keyup', montant_projet, function () {
  computeBorrowAmount();
});

$(document).on('keyup', montant_apport, function () {
  computeBorrowAmount();
});

$(document).ready(function () {
  computeBorrowAmount();
});
