/* global  geojson, programs_page, lands_page, home_programs_filter_id, home_lands_filter_id */
import $ from 'jquery';

$(document).on('sf:ajaxfinish', '.searchandfilter', function() {
  // Trigger a vanilla js event when the searchanfilter query is finished
  const event = new Event('sf:ajaxfinish');
  document.querySelector('.searchandfilter').dispatchEvent(event);

  // Refresh result count
  const count = geojson.data.features.length;
  $('#nb-results-count').text(count);

  const text = $('#nb-results-text');

  if (count > 1) {
    if (!text.text().endsWith('s')) {
      text.text(text.text() + 's');
    }
  } else {
    if (text.text().endsWith('s')) {
      text.text(text.text().slice(0, Math.max(0, text.text().length - 1)));
    }
  }

  // Collapse the filter
  $('.button-collapse[aria-expanded="true"]').trigger('click');

  // Scroll to the top
  $('html, body').animate({
    scrollTop: $('#programs-listing').offset().top - 61,
  }, 300);
});

$(document).ready(function () {
  $('.sf-field-sort_order select').clone().appendTo('#programs-sort');
});

$(document).on('change', '#programs-sort select', function () {
  $('.sf-field-sort_order select').val($(this).val());
  $('.searchandfilter').trigger('submit');
});

$(document).on('change', '.home-filters-bar .sf-field-taxonomy-types select', function () {
  const form = $('.home-filters-bar .searchandfilter');
  if ($(this).val() === 'appartment' || $(this).val() === 'maison') {
    form.attr('action', programs_page);
    form.attr('data-results-url', programs_page);
    form.attr('data-ajax-url', programs_page + '?sf_data=results');

    form.attr('data-sf-form-id', home_programs_filter_id);
    form.attr('data-ajax-form-url', '/?sfid=' + home_programs_filter_id + '&sf_action=get_data&sf_data=form');
    form.attr('id', 'search-filter-form-' + home_programs_filter_id);

  } else if ($(this).val() === 'terrain') {
    form.attr('action', lands_page);
    form.attr('data-results-url', lands_page);
    form.attr('data-ajax-url', lands_page + '?sf_data=results');

    form.attr('data-sf-form-id', home_lands_filter_id);
    form.attr('data-ajax-form-url', '/?sfid=' + home_lands_filter_id + '&sf_action=get_data&sf_data=form');
    form.attr('id', 'search-filter-form-' + home_lands_filter_id);
  }

  $('.searchandfilter').searchAndFilter();
});
