import $ from 'jquery';

$(document).on('click', '#toggle', function() {
  $(this).toggleClass('active');
  $('body').toggleClass('nav-open');
});

// $(document).ready(function () {
//   $('.sub-menu').prependTo('.header');
// });
