import $ from 'jquery';

const nav = $('header');

let last_known_scroll_position = 0;
let ticking = false;

function toggleStickyHeader(position) {
  if(position > 0) {
    nav.addClass('sticky');
  } else {
    nav.removeClass('sticky');
  }
}

window.addEventListener('scroll', function () {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      toggleStickyHeader(last_known_scroll_position);
      ticking = false;
    });

    ticking = true;
  }
});
