import $ from 'jquery';

$(document).ready(function () {
  $(document).on('click', 'a[href*="#"]:not([href="#"])', function(event) {
    if (
      location.hostname == this.hostname
      && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
    ) {
      event.preventDefault();

      let anchor = $(this.hash);
      anchor = anchor.length > 0 ? anchor : $('[name="' + this.hash.slice(1) + '"]');

      if ( anchor.length > 0 ) {
        const offset = 185;

        $('html, body').animate({
          scrollTop: anchor.offset().top - offset,
        }, 1000);
      }
    }
  });

  const observer = new IntersectionObserver(function(entries) {
  if (entries[0].intersectionRatio === 0)
    document.querySelector('#section_list_nav').classList.add('section-list-nav-sticky');
  else if(entries[0].intersectionRatio === 1)
    document.querySelector('#section_list_nav').classList.remove('section-list-nav-sticky');
  }, { threshold: [0,1] });

  const section_list_nav_top = document.querySelector('#section_list_nav_top');
  if (section_list_nav_top) {
    observer.observe(section_list_nav_top);
  }
});
