// import external dependencies
import $ from 'jquery';
global.$ = global.jQuery = $;

import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';

import 'slick-carousel';

import './focus';
import './header';
import './menu';
import './program-nav';
import './search';
import './slide';
import './scroll-indicator';
import './simulator';
import './modal';
